
import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import { FIELD_TYPES, MAX_CHARACTER_LIMIT } from "@/constants"
import ThemisInput from "@/components/shared/input"
import { TRANSLATION_FILE_UPLOAD_TYPE, TRANSLATION_UPLOAD_STATUS, FIELD_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL, ARRAY, TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES } from "@/constants"

export default {
  name      : "Fields",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisInput
  },
  data() {
    return {
      localSelectedField                : {},
      fieldLabelCharacterLimit          : MAX_CHARACTER_LIMIT.FIELD_LABEL,
      fieldSystemNameCharacterLimit     : MAX_CHARACTER_LIMIT.FIELD_SYSTEM_NAME,
      fieldTypes                        : Object.values(FIELD_TYPES),
      showEditFieldLabelWarning         : false,
      selectedTab                       : null,
      translationFileUploadStatusPolling: null
    }
  },
  beforeDestroy() {
    clearInterval(this.translationFileUploadStatusPolling)
  },
  methods: {
    ...mapActions({
      updateField                 : "fields/updateField",
      downloadFieldTranslationFile: "fields/downloadFieldTranslationFile",
      uploadFieldTranslationFile  : "fields/uploadFieldTranslationFile",
      loadFieldsV2                : "fields/loadFieldsV2",
      notify                      : "shared/notify"
    }),
    ...mapMutations({
      resetFieldUpdateError: "fields/resetFieldUpdateError"
    }),
    handleLabelInputOnBlurEvent(onBlur) {
      onBlur()
      this.showEditFieldLabelWarning = false
      this.verifyAndUpdateLabel()
    },
    handleLabelInputOnFocusEvent(onFocus) {
      onFocus()
      this.showEditFieldLabelWarning = true
    },
    handleLabelInputOnEnter() {
      this.$refs.text_field_label.blur()
    },
    verifyAndUpdateLabel() {
      if (this.isLabelChanged) {
        if (this.localSelectedField.label &&
            this.localSelectedField.label.length <= this.fieldLabelCharacterLimit) {
          this.updateField({
            id   : this.localSelectedField.id,
            label: this.localSelectedField.label
          })
        }
      }
    },
    handleSystemNameInputOnBlurEvent(onBlur){
      onBlur()
      this.verifyAndUpdateSystemName()
    },
    handleSystemNameInputOnEnter() {
      this.$refs.themis_input_field_system_name.blur()
    },
    verifyAndUpdateSystemName(){
      if (this.isSystemNameChanged) {
        if (this.localSelectedField.systemName &&
            this.localSelectedField.systemName.length <= this.fieldSystemNameCharacterLimit) {
          this.updateField({
            id        : this.localSelectedField.id,
            systemName: this.localSelectedField.systemName
          })
        }
      }
    },
    openFileExplorerToSelectDocument() {
      this.$refs.input_upload_translation_document.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[ARRAY.FIRST]
      if( file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.uploadFieldTranslationFile({ field: this.selectedField, file: file })
      }
    },
    clearTranslationFileUploadStatusPollingInterval() {
      if(this.translationFileUploadStatusPolling) {
        clearInterval(this.translationFileUploadStatusPolling)
        this.translationFileUploadStatusPolling = null
      }
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2                     : "fields/fieldsV2",
      isUpdatingLabel              : "fields/isUpdatingLabel",
      optionLists                  : "optionLists/optionLists",
      isUpdatingFieldSystemName    : "fields/isUpdatingFieldSystemName",
      fieldSystemNameUpdateError   : "fields/fieldSystemNameUpdateError",
      isDownloadingFieldTranslation: "fields/isDownloadingFieldTranslation",
      isUploadingTranslationFile   : "fields/isUploadingTranslationFile",
      dataRetentionRules           : "dataRetentionRules/dataRetentionRules"
    }),
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    selectedField() {
      if (this.$route.name === "field" && this.$route.params.id) {
        return this.fieldsV2.find(field => field.id === +this.$route.params.id)
      }
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    dataRetentionValueOfSelectedField() {
      return this.dataRetentionRules.find(rule => rule.fieldId === this.selectedField.id)?.value
    },
    fieldSystemNameDuplicate() {
      return this.fieldSystemNameUpdateError?.type === "duplicate"
    },
    isLabelChanged() {
      return this.selectedField?.label !== this.localSelectedField?.label
    },
    isSystemNameChanged() {
      return this.selectedField?.systemName !== this.localSelectedField?.systemName
    },
    selectFieldType() {
      return this.fieldTypes.find(fieldType => fieldType.value === this.selectedField?.type)
    },
    fieldTypeNameOfSelectedField() {
      return this.selectFieldType.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectFieldType.icon
    },
    showOptionListDetails() {
      return [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(this.selectedField.type)
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE).toString()
    },
    fieldTranslationUploadStatus() {
      return this.selectedField?.translationUploadStatus
    },
    isFieldTranslationUploadInitiated() {
      return this.fieldTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    isFieldTranslationUploadFailed() {
      return this.fieldTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    },
    isFieldTranslationUploadSuccess() {
      return this.fieldTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.SUCCESS
    }
  },
  watch: {
    selectedField: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localSelectedField.keys) {
          this.localSelectedField = { ...newValue }
        }
      }
    },
    "localSelectedField.systemName": {
      immediate: true,
      handler  : function() {
        if (this.fieldSystemNameUpdateError) {
          this.resetFieldUpdateError(["systemName"])
        }
      }
    },
    fieldTranslationUploadStatus: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if(newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.translationFileUploadStatusPolling = setInterval(() => {
            this.loadFieldsV2({ id: this.selectedField.id, uploadStatus: newValue })
          }, FIELD_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }
        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearTranslationFileUploadStatusPollingInterval()
        }
        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "1059"
          })
        }
      }
    }
  }
}